<template>
  <div id="page-upload" v-if="upload_status">
    <!-- 上方列 -->
    <div class="vx-card p-3 mb-base">
      <vx-card :title="$t('uploadSingle')" id="div-with-loading">
        <vs-row>
          <vs-col :vs-type="windowWidth<768 ? '' : 'flex'" :vs-justify="activePatientInfo!=null ? 'space-between' : 'flex-end'" vs-align="center">
            <div>
              <!-- <h4 :class="windowWidth<768 ? 'cd-text-group' : ''" v-if="activePatientInfo!=null" :vs-justify="activePatientInfo!=null ? 'space-between' : 'flex-end'">{{$t('Member')}}：{{activePatientInfo.name}}</h4> -->
            </div>
            <div>
              <vs-col vs-type="flex" vs-align="center">
                <vs-input
                  class="w-9/12"
                  v-model="searchQuery"
                  :placeholder="$t('components.upload.accountTip')"
                  @keyup.enter.native="Searchpartnermember" />
                <!-- <vs-button @click="Searchpartnermember" style="white-space: nowrap;">
                  <feather-icon icon="SearchIcon"></feather-icon>
                  {{$t("SearchUpload")}}
                </vs-button> -->
                <vs-button class="ml-1 px-2 sm:px-5 sm:ml-3" @click="Searchpartnermember" style="white-space: nowrap;border-radius: 10%;" icon-pack="feather" icon="icon-search" >
                    {{$t('SearchUpload')}}
                </vs-button>
              </vs-col>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
    </div>
    <div class="vx-card p-3 mb-base">
      <!-- 批次上傳 -->
      <vx-card :title="$t('uploadMultiple')" id="div-with-loading" >
        <vs-row>
          <vs-col>
            <vs-radio class="w-2/3 " 
              vs-name="typeSelect"
              v-model="typeSelect"
              v-for="(item, index) in batch_upload"
              :vs-value="item.name" 
              :key="index"
              @change="TypeSelect(item.name,item.type)"
            >{{$t(item.name)}}</vs-radio>
          </vs-col>
          <vs-col v-if="batch_type==6">
            <vs-radio class="w-1/5 h-10" 
              vs-name="facialSelect"
              v-model="facialSelect"
              v-for="(item, index) in facial_select"
              :vs-value="item.name"
              :key="index"
              @change="FacialSelect(item.name,item.type)"
            >{{$t(item.name)}}</vs-radio>
          </vs-col>
        </vs-row>
          <!-- 上傳說明 -->
        <vs-row>
          <vs-col class="cd-form-group  py-5">
            <p  class="text-danger text-bolder "  v-if="batch_type==6">{{$t(facialSelect)}} {{$t('medicalRule')}} : {{batch_explain_msg()}}</p>
            <p class="text-danger" style="font-weight:bold;" v-else> {{$t(typeSelect)}} {{$t('medicalRule')}} : {{batch_explain_msg()}}</p>
          </vs-col> 
        </vs-row>
        <template slot="footer">
          <div class="flex">
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
              <!-- 選擇檔案 -->
              <label class="cursor-pointer text-primary">
                <vs-col vs-type="flex" vs-align="center">
                  <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                  <p style="white-space: nowrap;">{{$t('SelectFile')}}</p>
                </vs-col>
                <input
                  type="file"
                  name="fileupload"
                  id="Reportfile"
                  ref="fileInput"
                  multiple
                  @change="onFileSelected($event,0)"
                  :accept="batch_upload[batch_type<7?batch_type:batch_type-4].accept"
                  hidden>
              </label>
              <!-- 上傳的檔案名稱 -->
              <vs-col class="mb-3" vs-type="flex" vs-justify="left" vs-w="10" vs-align="center">
              <!-- 上傳 -->
              <vs-button class="ml-3 px-5" icon-pack="feather" icon="icon-upload" :disabled="uploadReportbtn==false" @click="uploadImg(batch_type)" style="border-radius: 10%;">
                {{$t('UploadBtn')}}
              </vs-button>
              </vs-col>
            </vs-col>
          </div>
          <p style="font-weight:normal;">{{uploadReportFileName}}</p>
        </template>
      </vx-card>
    </div>
    <!-- 健康數據上傳 -->
    <div class="vx-card p-3 mb-base" v-if="partnerid==1||partnerid==6215||partnerid==1575||partnerid==6238"><!-- 目前只開放給南投使用-->
      <vx-card :title="$t('uploadHealthdata')" id="div-with-loading">
        <vs-row>
          <vs-col :vs-type="windowWidth<768 ? '' : 'flex'" vs-justify="left">
              <a href="https://upload.curdoctor.com.tw/excel_template/upload_healthdata_batch.xlsx">
                <el-button type="info" icon="el-icon-download" circle style="border-radius: 10%;"></el-button>{{$t('setWord.downloadExample')}}{{ $t('upload.healthdata_batch.examplenote') }}
              </a>
          </vs-col>
        </vs-row>
        <!-- 上傳說明 -->
        <vs-row>
          <vs-col class="cd-form-group  py-5">
            <p class="text-danger" style="font-weight:bold;">{{ $t('selectFileRule',{data:'.xlsx'}) }}</p>
          </vs-col> 
        </vs-row>
        <template slot="footer">
          <div class="flex">
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
              <!-- 選擇檔案 -->
              <label class="cursor-pointer text-primary">
                <vs-col vs-type="flex" vs-align="center">
                  <feather-icon class="mr-1" icon="FilePlusIcon"></feather-icon>
                  <p style="white-space: nowrap;">{{$t('SelectFile')}}</p>
                </vs-col>
                <input
                  type="file"
                  name="fileupload"
                  id="Healthdatafile"
                  ref="fileInput"
                  multiple
                  @change="onFileSelected($event,1)"
                  accept=".xlsx"
                  hidden>
              </label>
              <!-- 上傳的檔案名稱 -->
              <vs-col class="mb-3" vs-type="flex" vs-justify="left" vs-w="10" vs-align="center">
              <!-- 上傳 -->
              <vs-button class="ml-3 px-5" icon-pack="feather" icon="icon-upload" :disabled="uploadHealthdatabtn==false" @click="uploadHealthdata()" style="border-radius: 10%;">
                {{$t('UploadBtn')}}
              </vs-button>
              </vs-col>
            </vs-col>
          </div>
          <p style="font-weight:normal;">{{uploadHealthdataFileName}}</p>
        </template>
      </vx-card>
    </div>
    <div>
      <!-- 上傳醫療影像視窗 -->
      <upload-popup :uploadPopupActive="openUploadPopup" :spid="parseInt(pidUpload)" :spname="nameUpload" @closePopup="closeUploadPopup"></upload-popup>
    </div>
  </div>
  <div id="page-upload-batch" v-else>
    <div class="vx-row">
      <!-- 批次上傳結果 -->
      <div class="vx-col w-full sm:w lg:w mb-base" v-if="upload_mode==1">
        <vx-card id="div-with-loading">
          <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-offset="10">
              <!-- 上一頁 -->
              <vs-button class="px-4 py-2 " icon-pack="feather" icon="icon-upload" @click="reupload()" style="border-radius: 10%;">
                {{$t('BackBtn')}}
              </vs-button>
            </vs-col>
          </vs-row>
          <div v-if="uploadReportMsgShow">
            <vs-table :data="table.Msg">
              <template slot="header">
                <h3>
                  {{$t('message.upload_success')}} {{table.upload_success}} {{$t('TotalRecord')}} , {{$t('message.upload_error')}}  {{table.upload_error}} {{$t('TotalRecord')}}
                </h3>
              </template>
              <template slot="thead" style="font-size:1rem">
                <vs-th  style="font-size:1rem" >
                  {{$t('FileName')}}
                </vs-th>
                <vs-th style="font-size:1rem">
                  {{$t('Status')}}
                </vs-th>
                <vs-th style="font-size:1rem">
                  {{$t('Description')}}
                </vs-th>
              </template>
              <template :slot-scope="table">
                <vs-tr :key="index" v-for="(value, index) in table.json" >
                  <vs-td :data="table.json[index].filename">
                    {{table.json[index].filename}}
                  </vs-td>
                  <vs-td :data="table.json[index].message">
                    {{check_msg(table.json[index].message)}}
                  </vs-td>
                  <vs-td :data="table.json[index].message">
                    {{table.json[index].message}}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table> 
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full sm:w lg:w mb-base" v-if="upload_mode==2">
        <vx-card id="div-with-loading">
          <vs-row class="justify-content-md-center">
            <vs-col>
              <h2 style="color:green">
                V {{table.status=='OK'?$t('message.upload_success'):$t('message.upload_error')}}  
                <vs-button class="px-4 py-2 " icon-pack="feather" icon="icon-upload" @click="reupload()" style="float:right;border-radius: 10%;">
                  {{$t('BackBtn')}}
                </vs-button>
              </h2>
              <p style="color:green;padding: 1rem 0.5rem;">
                {{ $t('upload.healthdata_batch.title_note') }}
              </p>
              <!-- 上一頁 -->
            </vs-col>
          </vs-row>
          <!-- style="max-height:10% !important;" -->
          <div class="table-fixed-header" v-if="uploadHealthdataMsgShow">
            <div>
              <p style="padding: 0.5rem;">
                {{ $t('upload.healthdata_batch.table_title_note') }}
              </p>
              </div>
            <vs-table sticky-header maxHeight="20rem"
              :data="table.upload_error">
              <template slot="header">
              </template>
              <template slot="thead">
                <vs-th style="background-color:#eee;line-height: 3em;">
                  <p>
                    {{ $t('upload.healthdata_batch.title.sheet')}}
                  </p>
                </vs-th>
                <vs-th style="background-color:#eee;line-height: 3em;">
                  <p>
                    {{ $t('upload.healthdata_batch.title.row')}}
                  </p>
                </vs-th>
                <vs-th style="background-color:#eee;line-height: 3em;">
                  <p>
                    {{ $t('upload.healthdata_batch.title.col')}}
                  </p>
                </vs-th>
                <vs-th style="background-color:#eee;line-height: 3em;">
                  <p>
                    {{ $t('upload.healthdata_batch.title.warning')}}
                  </p>
                </vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" style="font-size:0.85rem">
                  <vs-td :data="tr.sheet" style="padding-top:0.2rem;padding-bottom:0;">
                    {{tr.sheet}}
                  </vs-td>
                  <vs-td :data="tr.row" style="padding-top:0.2rem;padding-bottom:0;">
                    {{tr.row}}
                  </vs-td>
                  <vs-td :data="tr.col" style="padding-top:0.2rem;padding-bottom:0;">
                    {{tr.col}}
                  </vs-td>
                  <vs-td :data="tr.msg" style="padding-top:0.2rem;padding-bottom:0;">
                    {{tr.msg}}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import axios from 'axios'
import { searchpartnermember } from '@/api/user'
import uploadPopup from '@/components/uploadPopup'

  export default {
    components: {
      uploadPopup
    },
    data() {
      return {
        searchQuery:'',
        spid: '',
        note: '',
        uploadReportfile: [],
        uploadHealthdatafile: [],
        uploadReportbtn:false,
        uploadHealthdatabtn:false,
        uploadReportFileName: '',
        uploadHealthdataFileName: '',
        uploadReportMsgShow: false,
        uploadHealthdataMsgShow: false,
        lodingfile: false,
        facialtype: '6',
        batch_type:'0',
        typeSelect:'medicalReport0',
        facialSelect:'medicalReport8',
        batch_upload:[{
            // 患部照片
            name:'medicalReport0',
            type:0,
            accept:'image/*',
            check:false
          },{
          // 檢驗報告(PDF)
            name:'medicalReport2',
            type:1,
            accept:'.pdf',
            check:false
          },{
            // DICOM
            name:'medicalReport3',
            type:2,
            accept:'*',
            check:false
          },{
            // 眼底鏡圖片
            name:'medicalReport4',
            type:3,
            accept:'*',
            check:false
          },{
          //   // 超音波照片
          //   name:'medicalReport5',
          //   type:4,
          //   accept:'*',
          //   check:false
          // },{
            // 心電圖
            name:'medicalReport6',
            type:5,
            accept:'*',
            check:false
          },{
            // 五官鏡
            name:'medicalReport7',
            type:6,
            accept:'*',
            check:false
          },{
            // 一般影片
            name:'medicalReport14',
            type:11,
            accept:'video/*',
            check:false
        }],
        facial_select:[{
            // 耳道
            name:'medicalReport8',
            type:6,
            check:false
          },{
            // 鼻腔
            name:'medicalReport9',
            type:7,
            check:false
          },{
            // 咽喉
            name:'medicalReport10',
            type:8,
            check:false
          },{
            // 口腔
            name:'medicalReport11',
            type:9,
            check:false
          },{
            // 皮膚
            name:'medicalReport12',
            type:10,
            check:false
        }],
        upload_status:true,//上傳狀態（true:false:上傳完成）
        upload_mode:0,//上傳類型
        table:{
          json:'',
          Msg:[],
          upload_success:0,
          upload_error:0
        },
        //健康數據上傳
        DialogVisibleWord:false,
        importwordstep:1,
        previewworddata:null,
        worksheet:'',
        xlsx_title:[],
        xlsx_data:[],
        openUploadPopup: false,
        pidUpload: 0,
        nameUpload: '',
      }
    },
    computed: {
      windowWidth() {
        return this.$store.state.windowWidth
      },
      activePatientInfo() {
        return this.$store.getters.activePatientInfo
      },
      partnerid() {
          return this.$store.getters.partner
      },
    },
    methods: {
      //查詢符合的患者
      Searchpartnermember(){
        this.$vs.loading()
        searchpartnermember({partnerid: this.partnerid, query: this.searchQuery}).then(res => {
          console.log('searchpartnermember',res.data.items)
          if(res.data.status=='OK'){
            this.$store.commit('SET_ACTIVE_PATIENT_INFO', res.data.items[0])
            if(res.data.items[0]!=null){
              this.searchQuery = ''
              this.showUploadPopup(res.data.items[0])
            }else{
              this.$vs.notify({ 
                color: 'red', 
                title: this.$t('notifytitle'), 
                text: this.$t('message.search_full_name_account'),
                position: 'top-center' 
              })
            }
          }else{
            console.log('searchpartnermember',res.data)
            this.$vs.notify({ 
              color: 'red', 
              title: this.$t('notifytitle'), 
              text: this.$t('message.search_full_message'),
              position: 'top-center' 
            })
          }
        }).then(() => {
          this.$vs.loading.close()
        })
      },
      TypeSelect(name,index){
        this.batch_type=index
        console.log('TypeSelect',name+this.batch_type);
      },
      FacialSelect(name,index){
        this.facialtype=index
        console.log('Facial name',name+index);
      },
      reupload(){
        this.upload_status=true;
        this.upload_mode=0;
        // this.uploadReportfile = []
        this.uploadReportFileName = ''
        this.uploadReportbtn = false
        this.uploadHealthdataFileName = ''
        this.uploadHealthdatabtn = false
        this.uploadReportMsgShow = false
        this.uploadHealthdataMsgShow = false
      },
      check_msg(value){
        if(value=='Success.'){
          return this.$t('message.upload_success')
        }else{
          return this.$t('message.upload_error')
        }
      },
      batch_explain_msg(){
        // console.log(this.$t('upload.report_batch.rule.3'))
        if(this.batch_type==1){
          return this.$t('upload.report_batch.rule.2')
        }else if(this.batch_type==2){
          return this.$t('upload.report_batch.rule.3')
        }else if(this.batch_type==3){
          return this.$t('upload.report_batch.rule.4')
        }else if(this.batch_type==11){
          return this.$t('upload.report_batch.rule.14')
        }else{
          return this.$t('upload.report_batch.rule.1')
        }
      },
      // 選擇檔案
      onFileSelected(e,type) {
        console.log(e,type)
        if(type==0){
          //限制上傳檔案數量
          if(e.target.files.length>15){
            this.$vs.dialog({
              color: 'danger',
              title: this.$t('notifytitle'),
              text: this.$t('selectFilenumberlimit',{'num':15})
            });
            return
          }
          console.log(1)
          this.uploadReportfile = []
          this.uploadReportFileName =''
          if (e.target.files.length == 0){
            this.uploadReportbtn = false
          }else{
            this.uploadReportbtn = true
          }
          for (let index = 0; index < e.target.files.length; index++) {
            //判斷大小1000MB
            console.log(e.target.files[index].size)
            if(e.target.files[index].size>1000000000){
              this.uploadReportfile = []
              this.uploadReportFileName =''
              this.$vs.dialog({
                color: 'danger',
                title: this.$t('notifytitle'),
                text: e.target.files[index].name+this.$t('selectFileNoticeMessage')
              })
              //clear input file select
              document.getElementById("Reportfile").value = "";
              break
            }

            if(this.uploadReportFileName!='')
              this.uploadReportFileName +=' 、  '
            this.uploadReportFileName += e.target.files[index].name 
            this.uploadReportfile.push(e.target.files[index]);
          }
        }else if(type==1){
          //限制上傳檔案數量
          if(e.target.files.length>1){
            this.$vs.dialog({
              color: 'danger',
              title: this.$t('notifytitle'),
              text: this.$t('selectFilenumberlimit',{'num':1})
            });
            return
          }
          this.uploadHealthdatafile = []
          this.uploadHealthdataFileName =''
          if (e.target.files.length == 0){
            this.uploadHealthdatabtn = false
          }else{
            this.uploadHealthdatabtn = true
          }
          for (let index = 0; index < e.target.files.length; index++) {
            //判斷大小1000MB
            console.log(e.target.files[index].size)
            if(e.target.files[index].size>1000000000){
              this.uploadHealthdatafile = []
              this.uploadHealthdataFileName =''
              this.$vs.dialog({
                color: 'danger',
                title: this.$t('notifytitle'),
                text: e.target.files[index].name+this.$t('selectFileNoticeMessage')
              })
              //clear input file select
              document.getElementById("Healthdatafile").value = "";
              break
            }

            if(this.uploadHealthdataFileName!='')
              this.uploadHealthdataFileName +=' 、  '
            this.uploadHealthdataFileName += e.target.files[index].name 
            this.uploadHealthdatafile.push(e.target.files[index]);
          }
        }
      },
      // //轉址給 會員上傳(原upload)
      // push_to_upload(){
      //     this.$router.push({path:'/setting/upload',query:{account:this.searchQuery}}) 
      // },
      // 上傳
      uploadImg(type) {
        console.log(type,)
        var _self = this
        const fd = new FormData()
        fd.append('type', type)
        //五官鏡細分
        if(type==6){
          fd.append('facialtype', _self.facialtype)
          fd.append('type', _self.facialtype)
        }
        // if(this.$store.getters.activePatientInfo!=null){
        //   fd.append('spid', this.$store.getters.activePatientInfo.pid)
        //   fd.append('note', _self.note)
        for (let index = 0; index < _self.uploadReportfile.length; index++) {
          fd.append('image[]', _self.uploadReportfile[index])
          console.log(index)
        }
        // }else{
        //   _self.$vs.dialog({
        //             color: 'danger',
        //             title: _self.$t('notifytitle'),
        //             text: _self.$t('message.search_full_message')
        //           })
        //   return false
        // }
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization' : 'Bearer '+localStorage.getItem('di_tokenId')
            }
        }
        _self.$vs.loading({
          container: '#div-with-loading',
          scale: 0.6
        })
        console.log(type,fd)
        console.log('image',fd.image)
        axios.post(process.env.VUE_APP_API_URL_HIS+'/api/uploadphoto_batch/2', fd, config)
          .then(function(res){
            if(res.data.status=='OK'){
              _self.upload_status=false;
              _self.upload_mode=1;
              _self.table.Msg=res.data.message;
              _self.table.upload_success=res.data.success;
              _self.table.upload_error=res.data.error;
              _self.table.json=res.data.json;
              _self.uploadReportMsgShow = true
            }else{
              _self.$vs.dialog({
                color: 'danger',
                title: _self.$t('notifytitle'),
                text: _self.$t('message.upload_error')
              })
            }
          })
          .catch(function (error) {
            // handle error
            console.log('upload file err ', error);
            _self.$vs.dialog({
                  color: 'danger',
                  title: _self.$t('notifytitle'),
                  text: _self.$t('message.upload_file_error')
                })
          })
          .then(function () {
            _self.$vs.loading.close('#div-with-loading > .con-vs-loading')
          });
      },
      uploadHealthdata(){
        const fd = new FormData()
        fd.append('partner_id', this.partnerid)
        for (let index = 0; index < this.uploadHealthdatafile.length; index++) {
          fd.append('file', this.uploadHealthdatafile[index])
          console.log(index)
        }
        let _self = this
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization' : 'Bearer '+localStorage.getItem('di_tokenId')
            }
        }
        axios.post(process.env.VUE_APP_API_URL_HIS+'/api/uploadhealthdata_batch/1/1', fd, config)
          .then(function(res){
            console.log(res)
            if(res.data.status=='OK'){
              _self.upload_status=false;
              _self.upload_mode=2;
              _self.table.status=res.data.status;
              _self.table.upload_success=res.data.success;
              _self.table.upload_error=res.data.warning;
              _self.uploadHealthdataMsgShow =  _self.table.upload_error.length?true:false
            }else{
              _self.$vs.dialog({
                color: 'danger',
                title: _self.$t('notifytitle'),
                text: _self.$t('message.upload_error')
              })
            }
          })
          .catch(function (error) {
            // handle error
            console.log('upload file err ', error);
            _self.$vs.dialog({
                  color: 'danger',
                  title: _self.$t('notifytitle'),
                  text: _self.$t('message.upload_file_error')
                })
          })
          .then(function () {
            _self.$vs.loading.close('#div-with-loading > .con-vs-loading')
          });
      },
      // 開啟 醫療影像上傳視窗
      showUploadPopup(data){
        console.log('showUploadPopup',data)
        // if(data.authorize != 1) return
        document.body.style.overflow = 'hidden';
        this.pidUpload = data.pid
        this.nameUpload = data.name
        this.openUploadPopup = true
      },
      // 關閉 醫療影像上傳視窗
      closeUploadPopup(){
        document.body.style.overflow = 'visible';
        this.openUploadPopup = false
      }
    },
    destroyed() {
      this.$store.commit('SET_ACTIVE_PATIENT_INFO', null)
    },
  }
</script>
<style>
.vs-radio--borde{
    border: 2px solid #003f8e !important;
}
</style>